export default {
  en: {
    'accessGroup.accessGroups': 'Access groups',
    'accessGroup.generalTab': 'General',
    'accessGroup.permissionsTab': 'Permissions',
    'accessGroup.permissions': 'Permissions',
    'accessGroup.create': 'Create New Access Group',
    'accessGroup.update': 'Edit Access Group',
    'accessGroup.view': 'View Access Group',
    'accessGroup.delete': 'Delete Access Group',

    'accessGroup.permissionSubject.global': 'Global',
    'accessGroup.permissionSubject.awscognito.accessgroup': 'Access Groups',
    'accessGroup.permissionSubject.awscognito.user': 'Users',
    'accessGroup.permissionSubject.virtuose.activity': 'Activities',
    'accessGroup.permissionSubject.virtuose.cohort': 'Cohorts',
    'accessGroup.permissionSubject.virtuose.dialogue': 'Dialogues',
    'accessGroup.permissionSubject.virtuose.healthworker': 'Healthcare Professionnals',
    'accessGroup.permissionSubject.virtuose.iotdevice': 'Connected Devices',
    'accessGroup.permissionSubject.virtuose.patient': 'Patients',
    'accessGroup.permissionSubject.virtuose.protocol': 'Protocols',
    'accessGroup.permissionSubject.virtuose.resource': 'Patient Resources',
    'accessGroup.permissionSubject.virtuose.vocalmessage': 'Vocal Messages',
    'accessGroup.permissionSubject.virtuose.patient_activity_protocol': 'Patients - Assign Activities / Protocols',
    'accessGroup.permissionSubject.virtuose.patient_cohort': 'Patients - Assign Cohorts',
    'accessGroup.permissionSubject.virtuose.patient_healthworker': 'Patients - Assign Healthcare Professionnals',
    'accessGroup.permissionSubject.virtuose.patient_iot': 'Patients - Assign Connected Devices',
    'accessGroup.permissionSubject.virtuose.patient_resource': 'Patients - Assign Resources',
    'accessGroup.permissionSubject.virtuose.patientmonitoringalert': 'Clinical Overview',
    'accessGroup.permissionSubject.project.audittrail': 'Logs',

    'accessGroup.permissionAction.add': 'Add',
    'accessGroup.permissionAction.change': 'Modify',
    'accessGroup.permissionAction.view': 'View',
    'accessGroup.permissionAction.delete': 'Delete',

    'accessGroup.permissionAction.viewAllPatients': 'Can view all patients',
    'accessGroup.permissionAction.manageNormative': 'Can modify normative data',
  },
  fr: {
    'accessGroup.accessGroups': "Groupes d'accès",
    'accessGroup.generalTab': 'Général',
    'accessGroup.permissionsTab': 'Permissions',
    'accessGroup.permissions': 'Permissions',
    'accessGroup.create': "Créer un nouveau groupe d'accès",
    'accessGroup.update': "Modifier le groupe d'accès",
    'accessGroup.view': "Consulter le groupe d'accès",
    'accessGroup.delete': "Supprimer le groupe d'accès",

    'accessGroup.permissionSubject.global': 'Global',
    'accessGroup.permissionSubject.awscognito.accessgroup': "Groupes d'accès",
    'accessGroup.permissionSubject.awscognito.user': 'Utilisateurs',
    'accessGroup.permissionSubject.virtuose.activity': 'Activités',
    'accessGroup.permissionSubject.virtuose.cohort': 'Cohortes',
    'accessGroup.permissionSubject.virtuose.dialogue': 'Dialogues',
    'accessGroup.permissionSubject.virtuose.healthworker': 'Intervenants',
    'accessGroup.permissionSubject.virtuose.iotdevice': 'Objets connectés',
    'accessGroup.permissionSubject.virtuose.patient': 'Patients',
    'accessGroup.permissionSubject.virtuose.protocol': 'Protocoles',
    'accessGroup.permissionSubject.virtuose.resource': 'Ressources patients',
    'accessGroup.permissionSubject.virtuose.vocalmessage': 'Messages vocaux',
    'accessGroup.permissionSubject.virtuose.patient_activity_protocol':
      'Patients - Assignations activités / protocoles',
    'accessGroup.permissionSubject.virtuose.patient_cohort': 'Patients - Assignations cohortes',
    'accessGroup.permissionSubject.virtuose.patient_healthworker': 'Patients - Assignations intervenants',
    'accessGroup.permissionSubject.virtuose.patient_iot': 'Patients - Assignations objets connectés',
    'accessGroup.permissionSubject.virtuose.patient_resource': 'Patients - Assignations ressources',
    'accessGroup.permissionSubject.virtuose.patientmonitoringalert': 'Portrait clinique',
    'accessGroup.permissionSubject.project.audittrail': 'Journalisation',

    'accessGroup.permissionAction.add': 'Ajout',
    'accessGroup.permissionAction.change': 'Modification',
    'accessGroup.permissionAction.view': 'Consultation',
    'accessGroup.permissionAction.delete': 'Supression',

    'accessGroup.permissionAction.viewAllPatients': 'Consulter tous les patients',
    'accessGroup.permissionAction.manageNormative': 'Modification des entitées normatives',
  },
};
