export default {
  en: {
    'log.logs': 'Logs',
    'log.users': 'Users',
    'log.patients': 'Patients',
    'log.context': 'Context',
    'log.logDate': 'Date',
    'log.operation': 'Operation',
    'log.data': 'Data',
    'log.username': 'User Email',
    'log.userLastFirstNames': 'User Last Name, First Name',
    'log.actions': 'Actions',
    'log.eventDate': 'Event Date',
    'log.ipAddress': 'IP Address',
    'log.browser': 'Browser',
    'log.full_url': 'Source URL',
    'log.url': 'URL',
    'log.dataType': 'Data Type',
    'log.eventDetail': 'Log Detail',

    'log.operation.view': 'View',
    'log.operation.update': 'Update',
    'log.operation.create': 'Create',
    'log.operation.delete': 'Delete',

    'log.field': 'Field',
    'log.oldValue': 'Old Value',
    'log.newValue': 'New Value',
    'log.updatedDataType': 'Updated Data Type',
    'log.true': 'Yes',
    'log.false': 'No',
    'log.unavailableData': 'Complex data that cannot be displayed',

    'log.context.allowedpermissions': 'Permissions',
    'log.context.user': 'User',
    'log.context.users': 'Users',
    'log.context.currentuser': 'Current User',
    'log.context.userpreferences': 'User Preferences',
    'log.context.accessgroups': 'Access Groups',
    'log.context.callbackrequests': 'Callback Requests',
    'log.context.callbackrequest': 'Callback Request',
    'log.context.login': 'Login',
    'log.context.patient': 'Patient',
    'log.context.patients': 'Patients',
    'log.context.patientnotes': 'Notes',
    'log.context.patientswithnotifications': 'Patients',
    'log.context.patientmonitoringsaturation': 'Patient Saturation',
    'log.context.patientmonitoringcardiacfrequency': 'Patient Cardiac Frequency',
    'log.context.patientmonitoringarterialpressure': 'Patient Arterial Pressure',
    'log.context.patientmonitoringbodytemperature': 'Patient Body Temperature',
    'log.context.patientmonitoringrespiratoryrate': 'Patient Respiratory Rate',
    'log.context.patientmonitoringecg': 'Patient ECG',
    'log.context.patientmonitoringchartparameters': 'Patient Parameters',
    'log.context.patientmonitoringprescription': 'Patient Prescription',
    'log.context.patientmonitoringweight': 'Patient Weight',
    'log.context.patientmonitoringcapillaryglycemia': 'Patient Capillary Glycemia',
    'log.context.patientmonitoringfalldetection': 'Patient Fall Detection',
    'log.context.patientmonitoringprescriptivelearning': 'Patient Prescriptive Learning',
    'log.context.patientmonitoringincontinence': 'Patient Incontinence',
    'log.context.patientfacilities': 'Patient Facilities',
    'log.context.facilities': 'Facilities',
    'log.context.organizations': 'Organizations',
    'log.context.patienttypes': 'Patient Types',
    'log.context.patienttype': 'Patient Type',
    'log.context.genders': 'Genders',
    'log.context.gender': 'Gender',
    'log.context.languages': 'Languages',
    'log.context.language': 'Language',
    'log.context.country': 'Country',
    'log.context.countries': 'Countries',
    'log.context.provinces': 'Provinces',
    'log.context.province': 'Province',
    'log.context.organization': 'Organization',
    'log.context.roombed': 'Roombed',
    'log.context.roombeds': 'Roombeds',
    'log.context.patientroombeds': 'Patient Roombeds',
    'log.context.patientparameters': 'Patient Parameters',
    'log.context.videocall': 'Videocall',
    'log.context.conversations': 'Conversations',
    'log.context.conversation': 'Conversation',
    'log.context.activity': 'Activity',
    'log.context.activities': 'Activities',
    'log.context.activityalertfields': 'Activity Alert Fields',
    'log.context.patientactivitiesprotocols': 'Patient Activities Protocols',
    'log.context.patientprotocols': 'Patient Protocols',
    'log.context.patientactivities': 'Patient Activities',
    'log.context.dialogue': 'Dialogue',
    'log.context.dialogues': 'Dialogues',
    'log.context.availabledialogues': 'Dialogues',
    'log.context.patientmanualentry': 'Manual Patient Input',
    'log.context.patientdialogueresults': 'Patient Dialogue Results',
    'log.context.patientphotoresults': 'Patient Photo Results',
    'log.context.patientiotdevice': 'Patient IOT Device',
    'log.context.patientiotdevices': 'Patient IOT Devices',
    'log.context.usergridconfiguration': 'User Grid Configuration',
    'log.context.patientmonitoringsummary': 'Clinical Overview',
    'log.context.realtimeactivitytypes': 'Realtime Activity Types',
    'log.context.iotrealtime': 'IOT Realtime',
    'log.context.patientecg': 'Patient ECG',
    'log.context.patientvitalsigns': 'Patient Vital Signs',
    'log.context.monitoringalertrealtime': 'Realt-time Alert Monitoring',
    'log.context.monitoringalert': 'Monitoring Alert',
    'log.context.pendingpriorityalert': 'Pending Priority Alert',
    'log.context.activitytypes': 'Activity Types',
    'log.context.statuslevels': 'Status Levels',
    'log.context.statuslevel': 'Status Level',
    'log.context.iotdevice': 'IOT Device',
    'log.context.iotdevices': 'IOT Devices',
    'log.context.iotdeviceroom': 'IOT Device Room',
    'log.context.iotdevicerooms': 'IOT Device Rooms',
    'log.context.iotdeviceparameters': 'IOT Device Parameters',
    'log.context.iotdevicetypes': 'IOT Device Types',
    'log.context.iotdevicetype': 'IOT Device Type',
    'log.context.iotdevicetypesbyactivitytype': 'IOT Device Types By Activity Type',
    'log.context.iotdevicecompanies': 'IOT Device Companies',
    'log.context.iotdevicecompany': 'IOT Device Company',
    'log.context.iotdevicemodels': 'IOT Device Models',
    'log.context.iotdevicemodel': 'IOT Device Model',
    'log.context.iotdevicestatuses': 'IOT Devices Statuses',
    'log.context.iotdevicestatus': 'IOT Devices Status',
    'log.context.prescriptivelearning': 'Prescriptive Learning',
    'log.context.associatepatientdevice': 'Patient Device',
    'log.context.removepatientdevice': 'Patient Device',
    'log.context.healthworker': 'Healthworker',
    'log.context.healthworkers': 'Healthworkers',
    'log.context.healthworkertypes': 'Healthworker Types',
    'log.context.healthworkertype': 'Healthworker Type',
    'log.context.patienthealthworker': 'Patient Healthworker',
    'log.context.patienthealthworkers': 'Patient Healthworkers',
    'log.context.vocalmessage': 'Vocal Message',
    'log.context.vocalmessages': 'Vocal Messages',
    'log.context.vocalmessagesiottype': 'Vocal Messages IOT Type',
    'log.context.protocol': 'Protocol',
    'log.context.protocols': 'Protocols',
    'log.context.cohort': 'Cohort',
    'log.context.cohorts': 'Cohorts',
    'log.context.patientcohort': 'Patient Cohort',
    'log.context.patientcohorts': 'Patient Cohorts',
    'log.context.protocolactivity': 'Protocol Activity',
    'log.context.protocolactivities': 'Protocol Activities',
    'log.context.dialogues_results': 'Dialogues Results',
    'log.context.notifications': 'Notifications',
    'log.context.notification': 'Notification',
    'log.context.resource': 'Resource',
    'log.context.resources': 'Resources',
    'log.context.patientresource': 'Patient Resource',
    'log.context.logs': 'Logs',
    'log.context.logrequestdetails': 'Log Detail',
    'log.context.logsfilters': 'Log Filters',
    'log.context.accessgroupnames': 'Access groups',

    'log.table.accessgroup': 'Access Group',
    'log.table.accessgrouppermission': 'Access Group Permissions',
    'log.table.useraccessgroup': 'Access Group Users',
    'log.table.permission': 'Permission',
    'log.table.group': 'Group',
    'log.table.group_permissions': 'Group Permissions',
    'log.table.user_groups': 'User Groups',
    'log.table.user': 'User',
    'log.table.user_user_permissions': 'User Permissions',
    'log.table.patientcallbackrequest': 'Patient Callback Request',
    'log.table.resource': 'Resource',
    'log.table.patienthealthworker': 'Patient Healthworker',
    'log.table.patientiotdevice': 'Patient IOT Device',
    'log.table.patientprotocol': 'Patient Protocol',
    'log.table.patientroombed': 'Patient Roombed',
    'log.table.protocol': 'Protocol',
    'log.table.roombed': 'Roombed',
    'log.table.statuslevel': 'Status Level',
    'log.table.organization': 'Organization',
    'log.table.patientmonitoringalert': 'Patient Monitoring',
    'log.table.gender': 'Gender',
    'log.table.iotdevicecompany': 'IOT Company',
    'log.table.iotdevicemodel': 'IOT Model',
    'log.table.iotdevicestatus': 'IOT Status',
    'log.table.iotdevicetype': 'IOT Type',
    'log.table.activitytypeiottype': 'Activity Type IOT Type',
    'log.table.country': 'Country',
    'log.table.patientactivity': 'Patient Activity',
    'log.table.patientactivitytrigger': 'Patient Activity Trigger',
    'log.table.patientfacility': 'Patient Facility',
    'log.table.patienttype': 'Patient Type',
    'log.table.protocolactivity': 'Protocol Activity',
    'log.table.province': 'Province',
    'log.table.patient': 'Patient',
    'log.table.activity': 'Activity',
    'log.table.activitydefaulttrigger': 'Activity Default Trigger',
    'log.table.dialogue': 'Dialogue',
    'log.table.dialogueactivity': 'Dialogue Activity',
    'log.table.healthworkertype': 'Healthworker Type',
    'log.table.iotdevice': 'IOT Device',
    'log.table.facility': 'Facility',
    'log.table.healthworker': 'Healthworker',
    'log.table.usergridconfiguration': 'User Grid Configuration',
    'log.table.cohort': 'Cohort',
    'log.table.cohortpatient': 'Patient Cohort',
    'log.table.facilitytype': 'Facility Type',
    'log.table.language': 'Language',
    'log.table.notification': 'Notification',
    'log.table.iotdeviceroombed': 'IOT Device Roombed',
    'log.table.patientvocalmessagetrigger': 'Patient Vocal Message Trigger',
    'log.table.userpreference': 'User Preference',
    'log.table.vocalmessage': 'Vocal Message',
    'log.table.vocalmessageactivity': 'Vocal Message Activity',
    'log.table.vocalmessagedefaulttrigger': 'Vocal Message Activity Trigger',
    'log.table.patientprescription': 'Patient Prescription',
    'log.table.caregiver': 'Caregiver',
    'log.table.activitytype': 'Activity Type',
    'log.table.partner': 'Partner',
    'log.table.patientcaregiver': 'Patient Caregiver',
    'log.table.partneralertstate': 'Partner Alert State',
    'log.table.patientdialogueactivity': 'Patient Dialogue Activity',
    'log.table.conversation': 'Conversation',
    'log.table.patientconversation': 'Patient Conversation',
    'log.table.dialogueresult': 'Dialogue Result',
    'log.table.videocallevent': 'Videocall Event',
    'log.table.videocall': 'Videocall',
    'log.table.dialogueexecution': 'Dialogue Execution',
    'log.table.caregiverconversation': 'Caregiver Conversation',
    'log.table.caregiverrole': 'Caregiver Role',
    'log.table.healthworkerconversation': 'Healthworker Conversation',
    'log.table.iotstateevent': 'IOT State Event',
    'log.table.message': 'Message',
    'log.table.patientweighing': 'Patient Weight',
    'log.table.patientobservation': 'Patient Observation',
    'log.table.monitoringalertrealtime': 'Real-time Alert Monitoring',
    'log.table.patientresource': 'Patient Resource',
    'log.table.prescriptivelearningexecution': 'Prescriptive Learning Execution',
    'log.table.patientprotocolhistory': 'Patient Protocol History',
    'log.table.patientnotes': 'Patient Notes',
    'log.table.userfacility': 'User Facility',
    'log.table.photoexecution': 'Photo Execution',
    'log.table.picture': 'Picture',
    'log.table.photoresult': 'Photo Result',

    'log.field.access_group': 'Access Group',
    'log.field.accessgroup': 'Access Group',
    'log.field.action': 'Action',
    'log.field.active': 'Active',
    'log.field.activity_default_trigger': 'Activity Default Trigger',
    'log.field.activity': 'Activity',
    'log.field.activity_type_code': 'Activity Type Code',
    'log.field.activity_type': 'Activity Type',
    'log.field.address': 'Address',
    'log.field.alert_threshold': 'Alert Threshold',
    'log.field.alert_threshold_unit': 'Alert Threshold Unit',
    'log.field.alert_triggered': 'Alert Triggered',
    'log.field.alert_type': 'Alert Type',
    'log.field.at': 'Date',
    'log.field.auto_update': 'Auto update',
    'log.field.baseline_diastolic': 'Baseline Diastolic',
    'log.field.baseline_heart_rate': 'Baseline Heart Rate',
    'log.field.baseline_height': 'Baseline Height',
    'log.field.baseline_systolic': 'Baseline Systolic',
    'log.field.baseline_weight': 'Baseline Weight',
    'log.field.battery_level': 'Battery Level',
    'log.field.bed': 'Bed',
    'log.field.birth_date': 'Birth Date',
    'log.field.caregiver': 'Caregiver',
    'log.field.caregiver_role': 'Caregiver Role',
    'log.field.city': 'City',
    'log.field.code': 'Code',
    'log.field.codename': 'Code',
    'log.field.cohort': 'Cohort',
    'log.field.communicator': 'Communicator',
    'log.field.company': 'Company',
    'log.field.completed': 'Completed',
    'log.field.configuration': 'Configuration',
    'log.field.confirmed_proximite': 'Confirmed Proximite',
    'log.field.content': 'Content',
    'log.field.content_type': 'Content Type',
    'log.field.continous': 'Continous',
    'log.field.conversation': 'Conversation',
    'log.field.country': 'Country',
    'log.field.created': 'Created',
    'log.field.createdAt': 'Created At',
    'log.field.created_at': 'Created At',
    'log.field.created_by': 'Created By',
    'log.field.created_by_patient': 'Created By Patient',
    'log.field.created_by_user': 'Created By User',
    'log.field.data': 'Data',
    'log.field.data_datetime': 'Datetime',
    'log.field.data_type': 'Data Type',
    'log.field.data_value': 'Data Value',
    'log.field.date_joined': 'Date Joined',
    'log.field.death_date': 'Death Date',
    'log.field.delayed_data': 'Delayed Data',
    'log.field.deleted': 'Deleted',
    'log.field.description': 'Description',
    'log.field.detail': 'Detail',
    'log.field.details': 'Details',
    'log.field.devicetype_id': 'Device Type',
    'log.field.dialogue_activity': 'Dialogue Activity',
    'log.field.dialogue_execution': 'Dialogue Execution',
    'log.field.dialogue': 'Dialogue',
    'log.field.din': 'DIN',
    'log.field.display_name': 'Display Name',
    'log.field.dosage': 'Dosage',
    'log.field.drug_name': 'Drug Name',
    'log.field.due_at': 'Due At',
    'log.field.ecg_data': 'ECG',
    'log.field.effective_at': 'Effective At',
    'log.field.email': 'Email',
    'log.field.employee_number': 'Employee Number',
    'log.field.enabled': 'Enabled',
    'log.field.end_date': 'End Date',
    'log.field.event': 'Event',
    'log.field.event_code': 'Event Code',
    'log.field.event_datetime': 'Event Datetime',
    'log.field.execution_datetime': 'Execution Datetime',
    'log.field.facility': 'Facility',
    'log.field.file': 'File',
    'log.field.first_name': 'First Name',
    'log.field.form': 'Form',
    'log.field.format': 'Format',
    'log.field.gender': 'Gender',
    'log.field.group_id': 'Group',
    'log.field.has_abnormal_value': 'Has Abnormal Value',
    'log.field.has_alert': 'Has Alert',
    'log.field.has_trigger_an_alert': 'Has Triggered An Alert',
    'log.field.has_triggered_an_alert': 'Has Triggered An Alert',
    'log.field.health_info_allergies': 'Health Info Allergies',
    'log.field.health_info_medical_conditions': 'Health Info Medical Conditions',
    'log.field.health_info_other': 'Health Info Other',
    'log.field.healthworker': 'Healthworker',
    'log.field.healthworker_type': 'Healthworker Type',
    'log.field.intervention_date': 'Intervention Date',
    'log.field.intervention_end_time': 'Intervention End Time',
    'log.field.intervention_start_time': 'Intervention Start Time',
    'log.field.iotdevice': 'IOT Device',
    'log.field.iotdevice_model': 'IOT Device Model',
    'log.field.iotdevicetype': 'IOT Device Type',
    'log.field.is_active': 'Active',
    'log.field.is_resolved': 'Resolved',
    'log.field.key': 'Key',
    'log.field.kit': 'Kit',
    'log.field.language_code': 'Language Code',
    'log.field.language': 'Language',
    'log.field.last_data_datetime': 'Last Data Datetime',
    'log.field.last_login': 'Last Login',
    'log.field.last_low_battery_notification_datetime': 'Last Low Battery Notification Datetime',
    'log.field.last_message_read': 'Last Message Read',
    'log.field.last_name': 'Last Name',
    'log.field.length': 'Length',
    'log.field.length_unit': 'Length Unit',
    'log.field.modifiedDate': 'Modified Date',
    'log.field.mrn': 'MRN',
    'log.field.name': 'Name',
    'log.field.nasm': 'NAM',
    'log.field.normative': 'Normative',
    'log.field.note_content': 'Note Content',
    'log.field.note_date': 'Note Date',
    'log.field.note_type': 'Note Type',
    'log.field.notification_email': 'Notification Email',
    'log.field.notification_sms': 'Notification Sms',
    'log.field.notification_type': 'Notification Type',
    'log.field.observation_datetime': 'Observation Datetime',
    'log.field.observation_type': 'Observation Type',
    'log.field.order': 'Order',
    'log.field.organization': 'Organization',
    'log.field.original_file': 'Original File',
    'log.field.parameter_type': 'Parameter Type',
    'log.field.parameters': 'Parameters',
    'log.field.partner': 'Partner',
    'log.field.patient_activity': 'Patient Activity',
    'log.field.patient': 'Patient',
    'log.field.patient_monitoring_alert': 'Patient Monitoring Alert',
    'log.field.patient_relation': 'Patient Relation',
    'log.field.patient_type': 'Ratient Type',
    'log.field.permission': 'Permission',
    'log.field.phone': 'Phone',
    'log.field.photo_execution': 'Photo Execution',
    'log.field.picture': 'Picture',
    'log.field.postal_code': 'Postal Code',
    'log.field.priority': 'Priority',
    'log.field.protocol_activity': 'Protocol Activity',
    'log.field.protocol': 'Protocol',
    'log.field.province': 'Province',
    'log.field.queried_at': 'Queried At',
    'log.field.read': 'Read',
    'log.field.repeat_count': 'Repeat Count',
    'log.field.repeat_count_overridden': 'Repeat Count Overridden',
    'log.field.repeat_minute': 'Repeat Minute',
    'log.field.repeat_minute_overridden': 'Repeat Minute Overridden',
    'log.field.requested_at': 'Requested At',
    'log.field.resolved': 'Resolved',
    'log.field.resolved_at': 'Resolved At',
    'log.field.resource': 'Resource',
    'log.field.room': 'Room',
    'log.field.room_sid': 'Sid',
    'log.field.roombed': 'Roombed',
    'log.field.scheduled_datetime': 'Scheduled Datetime',
    'log.field.serie': 'Serial',
    'log.field.start_date': 'Start Date',
    'log.field.start_sync_datetime': 'Start Sync Datetime',
    'log.field.start_time': 'Start Time',
    'log.field.start_time_overridden': 'Start Time Overridden',
    'log.field.status': 'Status',
    'log.field.status_reason': 'Status Reason',
    'log.field.statuslevel': 'Status Level',
    'log.field.strength': 'Strength',
    'log.field.subject': 'Subject',
    'log.field.system': 'System',
    'log.field.taken': 'Taken',
    'log.field.taken_care_at': 'Taken Care At',
    'log.field.type': 'Type',
    'log.field.updated': 'Updated',
    'log.field.updated_at': 'Updated At',
    'log.field.updated_by': 'Updated By',
    'log.field.user': 'User',
    'log.field.username': 'Username',
    'log.field.value': 'Value',
    'log.field.version': 'Version',
    'log.field.videocall': 'Videocall',
    'log.field.virtuose_alert_id': 'Virtuose Alert Id',
    'log.field.visible_to_healthworker': 'Visible To Healthworker',
    'log.field.vocal_message_activity': 'Vocal Message Activity',
    'log.field.vocal_message_default_trigger': 'Vocal Message Default Trigger',
    'log.field.vocal_message': 'Vocal Message',
    'log.field.weight_datetime': 'Weight Datetime',
    'log.field.weight_kg': 'Weight Kg',
  },
  fr: {
    'log.logs': 'Journalisation',
    'log.users': 'Utilisateurs',
    'log.patients': 'Patients',
    'log.context': 'Contexte',
    'log.logDate': 'Date',
    'log.operation': 'Opération',
    'log.data': 'Donnée',
    'log.username': 'Courriel utilisateur',
    'log.userLastFirstNames': "Nom, prénom de l'utilisateur",
    'log.actions': 'Actions',
    'log.eventDate': 'Date évènement',
    'log.ipAddress': 'Adresse IP',
    'log.browser': 'Fureteur',
    'log.full_url': 'URL provenance',
    'log.url': 'URL',
    'log.dataType': 'Type de données',
    'log.eventDetail': 'Détail journalisation',

    'log.operation.view': 'Consultation',
    'log.operation.update': 'Modification',
    'log.operation.create': 'Ajout',
    'log.operation.delete': 'Suppression',

    'log.field': 'Champ',
    'log.oldValue': 'Ancienne valeur',
    'log.newValue': 'Nouvelle valeur',
    'log.updatedDataType': 'Type de donnée modifiée',
    'log.true': 'Oui',
    'log.false': 'Non',
    'log.unavailableData': 'Donnée complexe qui ne peut pas être affichée',

    'log.context.allowedpermissions': 'Permissions',
    'log.context.user': 'Utilisateur',
    'log.context.users': 'Utilisateurs',
    'log.context.currentuser': 'Utilisateur actuel',
    'log.context.userpreferences': 'Préférences utilisateur',
    'log.context.accessgroups': "Groupes d'accès",
    'log.context.callbackrequests': 'Demandes rappel',
    'log.context.callbackrequest': 'Demande rappel',
    'log.context.login': 'Authentification',
    'log.context.patient': 'Patient',
    'log.context.patients': 'Patients',
    'log.context.patientnotes': 'Notes',
    'log.context.patientswithnotifications': 'Patients',
    'log.context.patientmonitoringsaturation': 'Patient saturation',
    'log.context.patientmonitoringcardiacfrequency': 'Patient fréquence cardiaque',
    'log.context.patientmonitoringarterialpressure': 'Patient pression artérielle',
    'log.context.patientmonitoringbodytemperature': 'Patient température corporelle',
    'log.context.patientmonitoringrespiratoryrate': 'Patient fréquence respiratoire',
    'log.context.patientmonitoringecg': 'Patient ECG',
    'log.context.patientmonitoringchartparameters': 'Patient paramètres',
    'log.context.patientmonitoringprescription': 'Patient prescription',
    'log.context.patientmonitoringweight': 'Patient poids',
    'log.context.patientmonitoringcapillaryglycemia': 'Patient glycémie capillaire',
    'log.context.patientmonitoringfalldetection': 'Patient détection chute',
    'log.context.patientmonitoringprescriptivelearning': 'Patient formation prescriptive',
    'log.context.patientmonitoringincontinence': 'Patient incontinence',
    'log.context.patientfacilities': 'Patient installations',
    'log.context.facilities': 'Installations',
    'log.context.organizations': 'Établissements',
    'log.context.patienttypes': 'Patient types',
    'log.context.patienttype': 'Patient type',
    'log.context.genders': 'Genres',
    'log.context.gender': 'Genre',
    'log.context.languages': 'Langues',
    'log.context.language': 'Langue',
    'log.context.country': 'Pays',
    'log.context.countries': 'Pays',
    'log.context.provinces': 'Provinces',
    'log.context.province': 'Province',
    'log.context.organization': 'Établissement',
    'log.context.roombed': 'Chambre-lit',
    'log.context.roombeds': 'Chambre-lits',
    'log.context.patientroombeds': 'Patient chambre-lits',
    'log.context.patientparameters': 'Patient paramètres',
    'log.context.videocall': 'Appel vidéo',
    'log.context.conversations': 'Conversations',
    'log.context.conversation': 'Conversation',
    'log.context.activity': 'Activité',
    'log.context.activities': 'Activités',
    'log.context.activityalertfields': 'Activité champ alerte',
    'log.context.patientactivitiesprotocols': 'Patient activités protocoles',
    'log.context.patientprotocols': 'Patient protocoles',
    'log.context.patientactivities': 'Patient activités',
    'log.context.dialogue': 'Dialogue',
    'log.context.dialogues': 'Dialogues',
    'log.context.availabledialogues': 'Dialogues',
    'log.context.patientmanualentry': 'Patient entrée manuelle',
    'log.context.patientdialogueresults': 'Résultats activité dialogue',
    'log.context.patientphotoresults': 'Patient photo résultats',
    'log.context.patientiotdevice': 'Patient objet connecté',
    'log.context.patientiotdevices': 'Patient objet connectés',
    'log.context.usergridconfiguration': 'Configuration grille utilisateur',
    'log.context.patientmonitoringsummary': 'Portrait clinique',
    'log.context.realtimeactivitytypes': "Temps réel types d'activités",
    'log.context.iotrealtime': 'Temps réel objets connectés',
    'log.context.patientecg': 'Patient ECG',
    'log.context.patientvitalsigns': 'Patient signes vitaux',
    'log.context.monitoringalertrealtime': 'Surveillance alerte temps réel',
    'log.context.monitoringalert': 'Surveillance alerte',
    'log.context.pendingpriorityalert': 'Alerte prioritaire en attente',
    'log.context.activitytypes': 'Activité types',
    'log.context.statuslevels': 'Valeur surveillances',
    'log.context.statuslevel': 'Valeur surveillance',
    'log.context.iotdevice': 'Objet connecté',
    'log.context.iotdevices': 'Objet connectés',
    'log.context.iotdeviceroom': 'Chambre objet connecté',
    'log.context.iotdevicerooms': 'Chambres objet connecté',
    'log.context.iotdeviceparameters': 'Paramètres objet connecté',
    'log.context.iotdevicetypes': 'Types objet connecté',
    'log.context.iotdevicetype': 'Type objet connecté',
    'log.context.iotdevicetypesbyactivitytype': 'Types objet connecté par type activité',
    'log.context.iotdevicecompanies': 'Fournisseurs objet connecté',
    'log.context.iotdevicecompany': 'Fournisseur objet connecté',
    'log.context.iotdevicemodels': 'Modèles objet connecté',
    'log.context.iotdevicemodel': 'Modèle objet connecté',
    'log.context.iotdevicestatuses': 'Statut objet connectés',
    'log.context.iotdevicestatus': 'Statut objet connectés',
    'log.context.prescriptivelearning': 'Formation prescriptive',
    'log.context.associatepatientdevice': 'Patient objet connecté',
    'log.context.removepatientdevice': 'Patient objet connecté',
    'log.context.healthworker': 'Intervenant',
    'log.context.healthworkers': 'Intervenants',
    'log.context.healthworkertypes': 'Types intervenant',
    'log.context.healthworkertype': 'Type intervenant',
    'log.context.patienthealthworker': 'Patient intervenant',
    'log.context.patienthealthworkers': 'Patient intervenants',
    'log.context.vocalmessage': 'Message vocal',
    'log.context.vocalmessages': 'Messages vocaux',
    'log.context.vocalmessagesiottype': 'Message vocaux type objet connecté',
    'log.context.protocol': 'Protocole',
    'log.context.protocols': 'Protocoles',
    'log.context.cohort': 'Cohorte',
    'log.context.cohorts': 'Cohortes',
    'log.context.patientcohort': 'Patient cohorte',
    'log.context.patientcohorts': 'Patient cohortes',
    'log.context.protocolactivity': 'Protocol activité',
    'log.context.protocolactivities': 'Protocol activités',
    'log.context.dialogues_results': 'Dialogues résultats',
    'log.context.notifications': 'Notifications',
    'log.context.notification': 'Notification',
    'log.context.resource': 'Ressource',
    'log.context.resources': 'Ressources',
    'log.context.patientresource': 'Patient ressource',
    'log.context.logs': 'Journalisation',
    'log.context.logrequestdetails': 'Détail journalisation',
    'log.context.logsfilters': 'Filtres journalisation',
    'log.context.accessgroupnames': 'Groupes accès',

    'log.table.accessgroup': "Groupe d'accès",
    'log.table.accessgrouppermission': "Permissions groupe d'accès",
    'log.table.useraccessgroup': "Utilisateurs groupe d'accès",
    'log.table.permission': 'Permission',
    'log.table.group': 'Groupe',
    'log.table.group_permissions': 'Groupe permissions',
    'log.table.user_groups': 'Utilisateur groupes',
    'log.table.user': 'Utilisateur',
    'log.table.user_user_permissions': 'Utilisateur permissions',
    'log.table.patientcallbackrequest': 'Rappel patient',
    'log.table.resource': 'Ressource',
    'log.table.patienthealthworker': 'Patient intervenant',
    'log.table.patientiotdevice': 'Patient objet connecté',
    'log.table.patientprotocol': 'Patient protocoles',
    'log.table.patientroombed': 'Patient chambre-lit',
    'log.table.protocol': 'Protocole',
    'log.table.roombed': 'Chambre-lit',
    'log.table.statuslevel': 'Statut',
    'log.table.organization': 'Établissement',
    'log.table.patientmonitoringalert': 'Patient surveillance alerte',
    'log.table.gender': 'Genre',
    'log.table.iotdevicecompany': 'Companie objet connecté',
    'log.table.iotdevicemodel': 'Modèle objet connecté',
    'log.table.iotdevicestatus': 'Statut objet connecté',
    'log.table.iotdevicetype': 'Type object connecté',
    'log.table.activitytypeiottype': 'Type activité type oject connecté',
    'log.table.country': 'Pays',
    'log.table.patientactivity': 'Patient activité',
    'log.table.patientactivitytrigger': 'Patient activité déclenchement',
    'log.table.patientfacility': 'Patient installation',
    'log.table.patienttype': 'Type patient',
    'log.table.protocolactivity': 'Protocole activité',
    'log.table.province': 'Province',
    'log.table.patient': 'Patient',
    'log.table.activity': 'Activité',
    'log.table.activitydefaulttrigger': 'Activité déclenchement défaut',
    'log.table.dialogue': 'Dialogue',
    'log.table.dialogueactivity': 'Dialogue activité',
    'log.table.healthworkertype': 'Type intervenant',
    'log.table.iotdevice': 'Objet connecté',
    'log.table.facility': 'Installation',
    'log.table.healthworker': 'Intervenant',
    'log.table.usergridconfiguration': 'Utilisateur configuration grille',
    'log.table.cohort': 'Cohorte',
    'log.table.cohortpatient': 'Patient cohorte',
    'log.table.facilitytype': 'Type installation',
    'log.table.language': 'Langue',
    'log.table.notification': 'Notification',
    'log.table.iotdeviceroombed': 'Objet connecté chambre-lit',
    'log.table.patientvocalmessagetrigger': 'Patient message vocal déclenchement',
    'log.table.userpreference': 'Préférence utilisateur',
    'log.table.vocalmessage': 'Message vocal',
    'log.table.vocalmessageactivity': 'Message vocal activité',
    'log.table.vocalmessagedefaulttrigger': 'Message vocal activité déclenchement défaut',
    'log.table.patientprescription': 'Patient prescription',
    'log.table.caregiver': 'Proche aidant',
    'log.table.activitytype': 'Type activité',
    'log.table.partner': 'Partenaire',
    'log.table.patientcaregiver': 'Patient proche aidant',
    'log.table.partneralertstate': 'Partenaire alerte statut',
    'log.table.patientdialogueactivity': 'Patient dialogue activité',
    'log.table.conversation': 'Conversation',
    'log.table.patientconversation': 'Patient conversation',
    'log.table.dialogueresult': 'Dialogue résultat',
    'log.table.videocallevent': 'Appel vidéo évènememt',
    'log.table.videocall': 'Appel vidéo',
    'log.table.dialogueexecution': 'Dialogue exécution',
    'log.table.caregiverconversation': 'Proche aidant conversation',
    'log.table.caregiverrole': 'Proche aidant rôle',
    'log.table.healthworkerconversation': 'Intervenant conversation',
    'log.table.iotstateevent': 'Objet connecté évènements',
    'log.table.message': 'Message',
    'log.table.patientweighing': 'Patient poids',
    'log.table.patientobservation': 'Patient observation',
    'log.table.monitoringalertrealtime': 'Surveillance temps réel',
    'log.table.patientresource': 'Patient ressource',
    'log.table.prescriptivelearningexecution': 'Exécution activité prescriptive',
    'log.table.patientprotocolhistory': 'Patient protocoles historique',
    'log.table.patientnotes': 'Patient notes',
    'log.table.userfacility': 'Utilisateur installation',
    'log.table.photoexecution': 'Exécution photo',
    'log.table.picture': 'Photo',
    'log.table.photoresult': 'Photo résultat',

    'log.field.access_group': "Groupe d'accès",
    'log.field.accessgroup': "Groupe d'accès",
    'log.field.action': 'Action',
    'log.field.active': 'Actif',
    'log.field.activity_default_trigger': 'Activité par défaut déclencheur',
    'log.field.activity': 'Activité',
    'log.field.activity_type_code': "Code type d'activité",
    'log.field.activity_type': 'Activité type',
    'log.field.address': 'Adresse',
    'log.field.alert_threshold': 'Alerte seuil',
    'log.field.alert_threshold_unit': 'Alerte seuil unité',
    'log.field.alert_triggered': 'Alerte déclenchée',
    'log.field.alert_type': 'Alerte type',
    'log.field.at': 'Date',
    'log.field.auto_update': 'Mise à jour automatique',
    'log.field.baseline_diastolic': 'Valeur de base diastolique',
    'log.field.baseline_heart_rate': 'Valeur de base fréquence cardiaque',
    'log.field.baseline_height': 'Valeur de base grandeur',
    'log.field.baseline_systolic': 'Valeur de base systolique',
    'log.field.baseline_weight': 'Valeur de base poids',
    'log.field.battery_level': 'Batterie',
    'log.field.bed': 'Lit',
    'log.field.birth_date': 'Date naissance',
    'log.field.caregiver': 'Proche aidant',
    'log.field.caregiver_role': 'Proche aidant rôle',
    'log.field.city': 'Ville',
    'log.field.code': 'Code',
    'log.field.codename': 'Code',
    'log.field.cohort': 'Cohorte',
    'log.field.communicator': 'Communicateur',
    'log.field.company': 'Compagnie',
    'log.field.completed': 'Complété',
    'log.field.configuration': 'Configuration',
    'log.field.confirmed_proximite': 'Confirmé Proximite',
    'log.field.content': 'Contenu',
    'log.field.content_type': 'Contenu type',
    'log.field.continous': 'En continu',
    'log.field.conversation': 'Conversation',
    'log.field.country': 'Pays',
    'log.field.created': 'Créé',
    'log.field.createdAt': 'Créé le',
    'log.field.created_at': 'Créé le',
    'log.field.created_by': 'Créé par',
    'log.field.created_by_patient': 'Créé par patient',
    'log.field.created_by_user': 'Créé par utilisateur',
    'log.field.data': 'Donnéee',
    'log.field.data_datetime': 'Date',
    'log.field.data_type': 'Type de donnée',
    'log.field.data_value': 'Donnée valeur',
    'log.field.date_joined': 'Date adhésion',
    'log.field.death_date': 'Date Décès',
    'log.field.delayed_data': 'Donnée en retard',
    'log.field.deleted': 'Supprimé',
    'log.field.description': 'Description',
    'log.field.detail': 'Détail',
    'log.field.details': 'Détails',
    'log.field.devicetype_id': "Type d'objet connecté",
    'log.field.dialogue_activity': 'Dialogue activité',
    'log.field.dialogue_execution': 'Dialogue exécution',
    'log.field.dialogue': 'Dialogue',
    'log.field.din': 'DIN',
    'log.field.display_name': 'Nom affichage',
    'log.field.dosage': 'Dosage',
    'log.field.drug_name': 'Médicament nom',
    'log.field.due_at': 'Prévu le',
    'log.field.ecg_data': 'ECG',
    'log.field.effective_at': 'Effectif le',
    'log.field.email': 'Courriel',
    'log.field.employee_number': 'Numéro employé',
    'log.field.enabled': 'Actif',
    'log.field.end_date': 'Fin date',
    'log.field.event': 'Évènement',
    'log.field.event_code': "Code de l'évènement",
    'log.field.event_datetime': "Date de l'évènement",
    'log.field.execution_datetime': "Date d'exécution",
    'log.field.facility': 'Installation',
    'log.field.file': 'Fichier',
    'log.field.first_name': 'Prénom',
    'log.field.form': 'Formulaire',
    'log.field.format': 'Format',
    'log.field.gender': 'Gendre',
    'log.field.group_id': 'Groupe',
    'log.field.has_abnormal_value': 'A valeur anormale',
    'log.field.has_alert': 'A alerte',
    'log.field.has_trigger_an_alert': 'A déclenché une alerte',
    'log.field.has_triggered_an_alert': 'A déclenché une alerte',
    'log.field.health_info_allergies': 'Information médicale allergies',
    'log.field.health_info_medical_conditions': 'Information médicale conditions',
    'log.field.health_info_other': 'Information médicale autre',
    'log.field.healthworker': 'Intervenant',
    'log.field.healthworker_type': "Type d'intervenant",
    'log.field.intervention_date': "Date d'intervention",
    'log.field.intervention_end_time': 'Intervention heure fin',
    'log.field.intervention_start_time': 'Intervention heure début',
    'log.field.iotdevice': 'Objet connecté',
    'log.field.iotdevice_model': "Modèle d'objet connecté",
    'log.field.iotdevicetype': "Type d'objet connecté",
    'log.field.is_active': 'Actif',
    'log.field.is_resolved': 'Résolu',
    'log.field.key': 'Clé',
    'log.field.kit': 'Kit',
    'log.field.language_code': 'Code langue',
    'log.field.language': 'Langue',
    'log.field.last_data_datetime': 'Date dernière donnée',
    'log.field.last_login': 'Dernier accès',
    'log.field.last_low_battery_notification_datetime': 'Date dernière notification batterie faible',
    'log.field.last_message_read': 'Dernier message lu',
    'log.field.last_name': 'Nom',
    'log.field.length': 'Longueur',
    'log.field.length_unit': 'Unité longueur',
    'log.field.modifiedDate': 'Date modification',
    'log.field.mrn': 'No dossier',
    'log.field.name': 'Nom',
    'log.field.nasm': 'NAM',
    'log.field.normative': 'Normatif',
    'log.field.note_content': 'Note contenu',
    'log.field.note_date': 'Date note',
    'log.field.note_type': 'Type note',
    'log.field.notification_email': 'Notification courriel',
    'log.field.notification_sms': 'Notification sms',
    'log.field.notification_type': 'Type de notification',
    'log.field.observation_datetime': "Date d'observation",
    'log.field.observation_type': "Type d'observation",
    'log.field.order': 'Ordre',
    'log.field.organization': 'Établissement',
    'log.field.original_file': 'Fichier original',
    'log.field.parameter_type': 'Type de paramètre',
    'log.field.parameters': 'Paramètres',
    'log.field.partner': 'Partenaire',
    'log.field.patient_activity': 'Patient activité',
    'log.field.patient': 'Patient',
    'log.field.patient_monitoring_alert': 'Patient surveillance alerte',
    'log.field.patient_relation': 'Patient relation',
    'log.field.patient_type': 'Type de patient',
    'log.field.permission': 'Permission',
    'log.field.phone': 'Téléphone',
    'log.field.photo_execution': 'Photo exécution',
    'log.field.picture': 'Photo',
    'log.field.postal_code': 'Code postal',
    'log.field.priority': 'Priorité',
    'log.field.protocol_activity': 'Protocole activité',
    'log.field.protocol': 'Protocole',
    'log.field.province': 'Province',
    'log.field.queried_at': 'Interrogé le',
    'log.field.read': 'Lu',
    'log.field.repeat_count': 'Répétition nombre',
    'log.field.repeat_count_overridden': 'Répétition nombre remplacé',
    'log.field.repeat_minute': 'Répétition minute',
    'log.field.repeat_minute_overridden': 'Répétition minute remplacé',
    'log.field.requested_at': 'Demandé le',
    'log.field.resolved': 'Résolu',
    'log.field.resolved_at': 'Résolu le',
    'log.field.resource': 'Ressource',
    'log.field.room': 'Chambre',
    'log.field.room_sid': 'Sid',
    'log.field.roombed': 'Chambre-Lit',
    'log.field.scheduled_datetime': 'Date prévue',
    'log.field.serie': 'Série',
    'log.field.start_date': 'Date début',
    'log.field.start_sync_datetime': 'Date début synchronisation',
    'log.field.start_time': 'Heure début',
    'log.field.start_time_overridden': 'Heure début remplacé',
    'log.field.status': 'Statut',
    'log.field.status_reason': 'Statut raison',
    'log.field.statuslevel': 'Valeur surveillance',
    'log.field.strength': 'Force',
    'log.field.subject': 'Sujet',
    'log.field.system': 'Système',
    'log.field.taken': 'Pris',
    'log.field.taken_care_at': 'Pris en charge le',
    'log.field.type': 'Type',
    'log.field.updated': 'Modifié',
    'log.field.updated_at': 'Modifié le',
    'log.field.updated_by': 'Modifié par',
    'log.field.user': 'Utilisateur',
    'log.field.username': "Nom d'utilisateur",
    'log.field.value': 'Valeur',
    'log.field.version': 'Version',
    'log.field.videocall': 'Appel vidéo',
    'log.field.virtuose_alert_id': 'Virtuose alerte id',
    'log.field.visible_to_healthworker': 'Visible pour intervenant',
    'log.field.vocal_message_activity': 'Message vocal activité',
    'log.field.vocal_message_default_trigger': 'Message vocal déclenchement par défaut',
    'log.field.vocal_message': 'Message vocal',
    'log.field.weight_datetime': 'Poids date',
    'log.field.weight_kg': 'Poids Kg',
  },
};
