<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="1000px">
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <div>
            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-monitor-account</v-icon>
              </v-col>

              <v-col>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <detail-field
                      :id="getIdByName('eventDate')"
                      ref="eventDate"
                      v-model="formData.eventDate"
                      :label="$t('log.eventDate')"
                    />

                    <detail-field
                      :id="getIdByName('userLastFirstNames')"
                      ref="userLastFirstNames"
                      v-model="formData.userLastFirstNames"
                      :label="$t('log.userLastFirstNames')"
                    />

                    <detail-field
                      :id="getIdByName('username')"
                      ref="username"
                      v-model="formData.username"
                      :label="$t('log.username')"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <detail-field
                      :id="getIdByName('ipAddress')"
                      ref="ipAddress"
                      v-model="formData.ipAddress"
                      :label="$t('log.ipAddress')"
                    />

                    <detail-field
                      :id="getIdByName('browser')"
                      ref="browser"
                      v-model="formData.browser"
                      :label="$t('log.browser')"
                    />

                    <detail-field
                      :id="getIdByName('full_url')"
                      ref="full_url"
                      v-model="formData.full_url"
                      :label="$t('log.full_url')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-wrench-cog</v-icon>
              </v-col>

              <v-col>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <detail-field
                      :id="getIdByName('context')"
                      ref="context"
                      :v-model="formData.context"
                      :label="$t('log.context')"
                      :value="$t(`log.context.` + formData.context)"
                    />

                    <detail-field
                      :id="getIdByName('data')"
                      ref="data"
                      v-model="formData.data"
                      :label="$t('log.data')"
                    />

                    <detail-field
                      :id="getIdByName('operation')"
                      ref="operation"
                      :v-model="formData.operation"
                      :label="$t('log.operation')"
                      :value="$t(`log.operation.` + formData.operation)"
                    />

                    <detail-field :id="getIdByName('url')" ref="url" v-model="formData.url" :label="$t('log.url')" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="formData.data_details.length > 0">
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-table-edit</v-icon>
              </v-col>

              <v-col>
                <div v-for="(data, index) in formData.data_details" :key="index" class="mb-8">
                  <detail-field
                    :id="getIdByName('data_table')"
                    ref="data_table"
                    :v-model="data.data_table"
                    :label="$t('log.updatedDataType')"
                    :value="$t(`log.table.` + data.data_table)"
                  />
                  <v-data-table
                    class="bordered-table"
                    :headers="headers"
                    :items="data.details"
                    item-key="field"
                    hide-default-footer
                    :items-per-page="data.details.length"
                  >
                    <template #item="props">
                      <tr>
                        <td>{{ $t(`log.field.` + props.item.field) }}</td>
                        <td>{{ props.item.old_value }}</td>
                        <td>{{ props.item.new_value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="close" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import LogService from '@/services/logService';

export default {
  name: 'LogModal',

  components: {},
  mixins: [translation, accessibility],

  props: {
    requestId: {
      type: String,
      required: true,
      default: null,
    },

    eventDatetime: {
      type: String,
      required: true,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      formData: {
        eventDate: '',
        ipAddress: '',
        userLastFirstNames: '',
        browser: '',
        username: '',
        full_url: '',
        context: '',
        data: '',
        operation: '',
        url: '',
        data_details: [],
      },
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('log.field'),
          value: 'field',
        },
        {
          text: this.$t('log.oldValue'),
          value: 'oldValue',
        },
        {
          text: this.$t('log.newValue'),
          value: 'newValue',
        },
      ];
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    async init() {
      this.title = this.$t('log.eventDetail');
      this.formData = {
        eventDate: '',
        ipAddress: '',
        userLastFirstNames: '',
        browser: '',
        username: '',
        full_url: '',
        context: '',
        data: '',
        operation: '',
        url: '',
        data_details: [],
      };

      if (this.requestId !== null) {
        await this.viewClicked();
      }
    },

    async viewClicked() {
      this.title = this.$t('log.eventDetail');
      this.showWaitModal = true;

      try {
        let detail = await LogService.getDetailLog(this.requestId, this.eventDatetime);
        this.formData.eventDate = detail.event_date;
        this.formData.ipAddress = detail.ip;
        this.formData.userLastFirstNames = detail.user;
        this.formData.browser = detail.browser;
        this.formData.username = detail.username;
        this.formData.full_url = detail.full_url;
        this.formData.context = detail.context;
        this.formData.data = detail.data;
        this.formData.operation = detail.operation;
        this.formData.url = detail.url;
        this.formData.data_details = detail.data_details.map((dataDetail) => {
          dataDetail.details = dataDetail.details.map((detail) => {
            if (typeof detail.old_value === 'boolean') {
              detail.old_value = this.$t(detail.old_value);
            }
            if (typeof detail.new_value === 'boolean') {
              detail.new_value = this.$t(detail.new_value);
            }
            if (detail.old_value === '***') {
              detail.old_value = this.$t('log.unavailableData');
            }
            if (detail.new_value === '***') {
              detail.new_value = this.$t('log.unavailableData');
            }
            return detail;
          });
          return dataDetail;
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.bordered-table {
  border: 1px rgba(0, 0, 0, 0.12) solid;
}
</style>
