<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-form ref="logFilterForm">
      <v-row align="start" class="print-end">
        <v-col align-self="start" cols="12" sm="3">
          <v-select
            :id="getIdByName('users')"
            ref="users"
            v-model="userFilters"
            class="print-mt-4"
            clearable
            multiple
            :label="$t('log.users')"
            :items="users"
            :item-text="concatName"
            item-value="id"
            outlined
            small-chips
            deletable-chips
            :disabled="isFetching"
            :no-data-text="$t('noDataAvailable')"
            :hide-details="$vuetify.breakpoint.xs"
          />
        </v-col>
        <v-col align-self="start" :cols="$vuetify.breakpoint.xs ? 12 : false">
          <v-select
            :id="getIdByName('patients')"
            ref="patients"
            v-model="patientFilters"
            class=""
            clearable
            multiple
            :label="$t('log.patients')"
            :items="patients"
            :item-text="concatName"
            item-value="id"
            outlined
            small-chips
            deletable-chips
            :disabled="isFetching"
            :no-data-text="$t('noDataAvailable')"
            :hide-details="$vuetify.breakpoint.xs"
          />
        </v-col>

        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <DateTimePickerField
            ref="fromDate"
            v-model="dateFrom"
            :max-date="dateTo ? dateTo : new Date()"
            :min-date="minDateToSelect"
            :label="$t('fromDate')"
            picker-type="dateTime"
            required
            outlined
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <DateTimePickerField
            ref="toDate"
            v-model="dateTo"
            :min-date="dateFrom ? dateFrom : minDate"
            :max-date="tomorrowMidnight"
            :label="$t('toDate')"
            required
            outlined
            picker-type="dateTime"
          />
        </v-col>

        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <div class="d-flex flex-row">
            <v-btn
              ref="apply"
              color="primary"
              :text="!$vuetify.breakpoint.xs"
              class="mt-2 ml-auto"
              :disabled="isFetching || !isValid || isDisabled"
              @click.stop="emitFilter()"
            >
              <v-icon>mdi-filter</v-icon>{{ $t('apply') }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import LogService from '@/services/logService';

export default {
  name: 'LogFilter',
  components: {},
  mixins: [translationMixin, accessibility, validationRulesMixin],

  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      minDate: '2022-11-01 08:00:00',
      patientFilters: [],
      patients: [],
      users: [],
      userFilters: [],
      dateFrom: null,
      dateTo: null,
      error: null,
      isFetching: false,
      isValid: true,
      maxDays: 500,
      maxHours: 72,
      minDateToSelect: new Date('2018-01-01 00:00:00'),
    };
  },

  computed: {
    tomorrowMidnight() {
      const now = new Date();
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
      return tomorrow;
    },
  },

  watch: {
    dateFrom: function () {
      if (!this.dateFrom || !this.dateTo) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },

    dateTo: function () {
      if (!this.dateFrom || !this.dateTo) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
  },

  created: function () {
    this.init();
  },

  methods: {
    async init() {
      this.isFetching = true;

      try {
        let filters = await LogService.getLogsFilters();

        this.patients = filters['patients'];
        this.users = filters['users'];
      } catch (error) {
        this.error = error;
      }

      this.isFetching = false;
    },

    concatName(item) {
      if (!item) return '';
      const birthDateInfo = item.birthDate ? ` (${item.birthDate})` : '';
      return `${item.lastName}, ${item.firstName}${birthDateInfo}`;
    },

    async emitFilter() {
      this.isValid = this.$refs.logFilterForm.validate();
      if (!this.isValid) return;

      var patientFilters = this.patientFilters.length === 0 ? null : this.patientFilters;
      var userFilters = this.userFilters.length === 0 ? null : this.userFilters;

      var result = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        patientIds: patientFilters,
        userIds: userFilters,
      };
      this.$emit('filter', result);
    },
  },
};
</script>
